import { EditorView } from 'prosemirror-view';
import { Node, Schema } from 'prosemirror-model';
import { serializer, parser } from './markdown';
// TODO - optionally could add this to the EditorView type
export const getMarkdownFromView = (view: EditorView): string => {
  // serialize and remove the zero width space character
  return serializer.serialize(view.state.doc).replace(/\u200B/g, '');
};

export const getNodeFromMarkdown = (schema: Schema, markdown: string): Node => {
  return parser.parse(markdown);
};
