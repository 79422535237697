/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Dexie, { Table, DBCore } from 'dexie';
import { Note } from 'core/types';

export class NotesDB extends Dexie {
  notes: Table<Note, string>;

  changesToNotes: Table<Note, string>;

  metadata: Table<{ key: string; value: string }, string>;

  constructor() {
    super('AnnoteExtensionDB');
    this.version(21).stores({
      notes: 'id, url, title, type, syncedAt, deletedAt',
      changesToNotes: 'id',
      metadata: 'key',
    });
    this.notes = this.table('notes');
    this.changesToNotes = this.table('changesToNotes');
    this.metadata = this.table('metadata');

    if (process.env.NODE_ENV === 'development') {
      this.use({
        stack: 'dbcore',
        create(downlevelDatabase: DBCore) {
          return {
            ...downlevelDatabase,
            table(tableName: string) {
              const downlevelTable = downlevelDatabase.table(tableName);
              return {
                ...downlevelTable,
                count(req: any) {
                  const startTime = performance.now();
                  console.log(`Dexie: Running count on ${tableName}`);
                  return downlevelTable.count(req).then((result: any) => {
                    const endTime = performance.now();
                    console.log(`Dexie: Count on ${tableName} took ${endTime - startTime} ms`);
                    return result;
                  });
                },
              };
            },
          };
        },
      });
    }
  }
}
