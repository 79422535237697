import { z } from 'zod';
import { DynamicStructuredTool } from '@langchain/core/tools';
import type { UUID } from 'core/types';
import type { ToolContext } from '../index';

export interface TextEditSuggestion {
  textToReplace: string;
  textReplacement: string;
  reason: string;
  textBefore?: string;
  textAfter?: string;
}
// TODO - should probably just take in an id and fetch it and make suggestions inside the tool
export const suggestEditsTool = ({ getEditorContext }: ToolContext): DynamicStructuredTool<typeof schema> => {
  const schema = z.object({
    noteId: z.string().describe(
      `The ID (UUID) of the note to apply suggestions to.  
        IMPORTANT: You need to have the content of the document before you can suggest edits.  
        Use get_documents_by_ids first if you do not already have the content.
        Ensure the textToReplace with optional textBefore and textAfter has a single occurrence in the document.
        Suggestions must not overlap.
        Do not ask for confirmation to apply the suggestions just apply them.
        The textBefore, textAfter and textReplacement all must exist in the initial content of the document.
        `,
    ),
    suggestions: z
      .array(
        z.object({
          textToReplace: z.string().describe('The new text that will replace the textToReplace'),
          textReplacement: z.string().describe('Text from the original document that will replace the textToReplace'),
          reason: z.string().describe('The reason for this suggestion'),
          textBefore: z
            .string()
            .optional()
            .describe('Original document text that appears before the textToReplace (for matching)'),
          textAfter: z
            .string()
            .optional()
            .describe('Original document text that appears after the textToReplace (for matching)'),
        }),
      )
      .describe('The list of text edit suggestions to apply'),
    username: z.string().optional().default('AnnI').describe('The username to attribute the suggestions to'),
  });

  return new DynamicStructuredTool({
    name: 'suggest_edits',
    description:
      'Suggest text edits to the content (NOT title) for a specific note.  Use this to make changes to note content.',
    schema,
    func: async ({ noteId, suggestions, username }) => {
      const editorContext = getEditorContext();

      if (!editorContext) {
        return JSON.stringify({
          status: 'error',
          message: 'Editor context is not available',
        });
      }

      const { addSuggestions } = editorContext;

      try {
        const { successful, failed } = addSuggestions({
          noteId: noteId as UUID,
          suggestions: suggestions as TextEditSuggestion[],
          username,
        });

        console.log('suggest_edits tool successful:', successful, 'failed:', failed);

        return JSON.stringify({
          status: successful === suggestions.length ? 'success' : 'partial_success',
          message: `Successfully applied ${successful} out of ${suggestions.length} suggestion(s) for note ${noteId}`,
          details: {
            successful,
            total: suggestions.length,
            failedSuggestions: failed,
          },
        });
      } catch (err) {
        console.error('Error adding edit suggestions:', err);
        return JSON.stringify({
          status: 'error',
          message: 'Failed to add edit suggestions',
          error: String(err),
        });
      }
    },
  });
};
