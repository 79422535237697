// A Card is the rendering component for a note.
// The Generic Card type is a wrapper for the other types of cards, which are specific to the type of note.

import { forwardRef, useImperativeHandle, useRef, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { Note, Source, Annote, Takeaway } from 'core/types';
import { colors, text } from 'core/styles';
import { useDragging } from 'src/context/DraggingContext';
import { FocusContext } from 'src/context/FocusContext';
import TakeawayCard from './byType/takeaway/Takeaway';
import { AnnoteCard } from './byType/annote/Annote';
import { SourceCard } from './byType/source/Source';
import { CardButtonsBar } from './menus';

export interface CardProps {
  note: Note;
  onClick?: (id: string) => void;
}

const getBackgroundColor = (props: { isDragging: boolean; isSuggestion?: boolean }): string => {
  if (props.isDragging) return colors.bg.dragging;
  if (props.isSuggestion) return colors.bg.suggestion;
  return colors.bg.primary;
};

const CardOutline = styled.div<{
  isDragging: boolean;
  isOverLinkDropzone?: boolean;
  isFocused?: boolean;
  isSuggestion?: boolean;
}>`
  cursor: pointer;
  position: relative;
  background-color: ${getBackgroundColor};
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  transform: ${(props) => (props.isSuggestion ? 'rotate(-2deg)' : 'none')};
  outline: 2px solid transparent;
  z-index: 11;
  transition: transform 0.5s ease;
  width: fit-content;
  &:hover {
    box-shadow: 0px 9px 24px rgba(15, 15, 15, 0.2), 0px 3px 6px rgba(15, 15, 15, 0.1);
  }
  box-shadow: 0px 1px 2px rgba(15, 15, 15, 0.1);
  font-family: ${text.family.primary};

  // highlight blue when
  //  - dragged note is over a dropzone that would link to this note
  //  - note is marked as focused
  ${(props) =>
    (props.isOverLinkDropzone || props.isFocused) &&
    `outline: 1px solid ${colors.lines.selected};  
    box-shadow: 0px 9px 24px rgba(15, 15, 15, 0.2), 0px 3px 6px rgba(15, 15, 15, 0.1);
  `}
`;

export const Card = forwardRef<HTMLDivElement, CardProps>(({ note }, forwardedRef) => {
  const internalRef = useRef<HTMLDivElement | null>(null);
  const { setDraggingNoteId, draggingNoteId, hoveringDropzoneKey } = useDragging();
  const { focusedNoteId } = useContext(FocusContext);

  const [{ isDragging }, dragRef] = useDrag({
    type: 'CARD',
    item: note,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (isDragging) {
      setDraggingNoteId(note.id);
    } else if (draggingNoteId && draggingNoteId === note.id) {
      setDraggingNoteId(null);
    }
  }, [isDragging, setDraggingNoteId, draggingNoteId, note.id]);

  useImperativeHandle(forwardedRef, () => internalRef.current as HTMLDivElement);

  if (note.type !== 'source') {
    // Apply dragRef to the entire card for non-source notes
    dragRef(internalRef);
  }

  const isOverLinkDropzone = useMemo(
    () => hoveringDropzoneKey?.slice(0, 36) === note.id,
    [hoveringDropzoneKey, note.id],
  );

  return (
    <CardOutline
      ref={internalRef}
      id={`card-${note.id}`}
      className="card"
      isDragging={isDragging}
      isOverLinkDropzone={isOverLinkDropzone}
      isFocused={!isDragging && note.id === focusedNoteId}
      isSuggestion={note.isSuggestion}
    >
      <CardButtonsBar note={note} />
      {note.type === 'takeaway' && <TakeawayCard takeaway={note as Takeaway} />}
      {note.type === 'source' && <SourceCard source={note as Source} dragRef={dragRef} />}
      {note.type === 'annote' && <AnnoteCard annote={note as Annote} />}
    </CardOutline>
  );
});

export default Card;
