import { v4 as uuidv4 } from 'uuid';
import { Note, UUID, TypeOfNote, NoteSummary, SearchNote } from 'core/types';

// the backend has a few fields the client doesn't need.
// this is the source of truth for the fields that are needed
const clientNoteFields = [
  'id',
  'userId',
  'type',
  'title',
  'value',
  'url',
  'createdFromId',
  'details',
  'createdAt',
  'syncedAt',
  'deletedAt',
  'links',
  'backlinks',
];

// make sure only the right fields for the backend are in the note
export const ensureOnlyNoteFields = (note: Note): Note => {
  return clientNoteFields.reduce<Partial<Note>>((acc, field) => {
    if (field in note) {
      // @ts-expect-error: Suppress type checking for dynamic key access
      acc[field as keyof Note] = note[field as keyof Note];
    }
    return acc;
  }, {} as Partial<Note>) as Note;
};

// Create a helper function to select notes without unecessary fields
export const selectNoteFields = (): string => clientNoteFields.join(', ');

export const createUUID = (): UUID => uuidv4();

export const createNote = (title: string, type: TypeOfNote, value?: string, createdFromId?: UUID): Note => {
  return {
    id: createUUID(),
    type,
    title,
    value: value || '',
    createdFromId,
    createdAt: new Date().toISOString(),
    backlinks: createdFromId ? [createdFromId] : [],
    links: [],
  } as Note;
};

export const summarizeNote = (note: Note | SearchNote): NoteSummary => {
  return {
    id: note.id,
    title: note.title,
    type: note.type,
  };
};
