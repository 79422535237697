import { DynamicStructuredTool } from '@langchain/core/tools';
import { z } from 'zod';
import type { ToolContext } from '../index';

export const getFocusedDocumentTool = ({
  getFocusedDocumentId,
  getShown,
  verboseToolCalls,
}: ToolContext): DynamicStructuredTool => {
  return new DynamicStructuredTool({
    name: 'get_focused_document',
    description: `Get information about the currently active document that the user is reading or editing right now. 
      Returns the complete document data, or null if no document is currently focused.`,
    schema: z.object({}),
    func: async () => {
      const focusedDocumentId = getFocusedDocumentId();
      if (verboseToolCalls) {
        console.log('get_focused_document tool called with:', { focusedDocumentId });
      }
      if (!focusedDocumentId || !getShown().surfaceNoteMap[focusedDocumentId]) {
        return JSON.stringify({
          status: 'no_focus',
          data: null,
        });
      }
      console.log('get_focused_document tool returning:', getShown().surfaceNoteMap[focusedDocumentId]);
      return JSON.stringify({
        status: 'success',
        data: getShown().surfaceNoteMap[focusedDocumentId],
      });
    },
  });
};
