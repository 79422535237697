import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { suggestLinkMenu } from './suggestLinkMenu'; // Ensure this path is correct
import { Note, SearchNote } from '../../types';
import { getMarkdownFromView } from './utils';

const DECORATION_KEY = new PluginKey('autocomplete');

type LinkMatchInfo = {
  start: number;
  end: number;
  query: string;
  trigger: string;
  cursorOffset: number;
};

let debounceTimeout: number | null = null;

export const autocompletPlugin = (options: { note: Note }): Plugin => {
  const { note } = options;
  return new Plugin({
    key: DECORATION_KEY,
    props: {
      handleDOMEvents: {
        input: (view: EditorView): void => {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
          }

          // Look for a link in the text before the cursor
          const matchLink = (): LinkMatchInfo | null => {
            const { state } = view;
            const { from } = state.selection;
            const textBefore = state.doc.textBetween(0, from, ' ');
            const match = textBefore.match(/(^|\s|\u200B)(\[\[|#)(.*)$/);
            console.log('textBefore', textBefore, 'match', match);

            if (!match) {
              suggestLinkMenu.hide();
              return null;
            }
            const [, triggerSpacing, trigger, mquery] = match;
            const triggerIndex = match.index! + triggerSpacing.length;

            const endTrigger = trigger === '#' ? ' ' : ']]';
            const endTriggerIndex = mquery.indexOf(endTrigger);
            const query = endTriggerIndex === -1 ? mquery : mquery.slice(0, endTriggerIndex + endTrigger.length);
            // Start and end positions of the matching link
            const startPos = from - (textBefore.length - triggerIndex);
            const endPos = startPos + query.length + trigger.length;
            // cursorOffset: If there is text after the match, how many extra chars should we place the cursor?
            const cursorOffset = mquery.length - query.length;

            return { start: startPos, end: endPos, query, trigger, cursorOffset };
          };

          // callback function for when a note is selected
          // here we replace the link starting text the user typed with the selected link
          const onSuggestionSelect = (suggestion: Note | SearchNote): string => {
            const { state, dispatch } = view;
            const linkInfo: LinkMatchInfo | null = matchLink();
            if (!linkInfo) {
              const { from } = state.selection;
              const textBefore = state.doc.textBetween(0, from, ' ');

              // This may be happening if there's a "-" in the text before the cursor
              console.error(
                'There should have been a link match from the user',
                state.doc,
                suggestion,
                'textBefore',
                textBefore,
                'from',
                from,
              );
            } else {
              // We found the partial link that the user was inserting
              const { start, end, cursorOffset } = linkInfo;
              const linkMark = state.schema.marks.link.create({ href: suggestion.id, internal: true });
              // Calculate positions
              // Create a transaction to replace the trigger and query with the link
              const tr = state.tr.delete(start, end);
              tr.insertText(`${suggestion.title} `, start); // add a space after it so the link will end
              tr.addMark(start, start + suggestion.title.length, linkMark);

              // Set the selection after the inserted text and space
              const newSelection = TextSelection.near(
                tr.doc.resolve(start + suggestion.title.length + 1 + cursorOffset),
              );
              tr.setSelection(newSelection);

              dispatch(tr);
              view.focus();
            }
            // return the new markdown content
            return getMarkdownFromView(view);
          };

          debounceTimeout = window.setTimeout(() => {
            const linkInfo: LinkMatchInfo | null = matchLink();
            if (!linkInfo) return; // no link match found
            const { start, query, trigger } = linkInfo;
            const coords = view.coordsAtPos(start);
            const position = { top: coords.bottom, left: coords.left };

            suggestLinkMenu.updateQuery(query, note, position, trigger, onSuggestionSelect);
          }, 300);
        },
      },
    },
  });
};
