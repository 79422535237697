// use the default but overrite the info
import { MenuComponent } from 'prosemirror-suggestion-mode';

// Create a custom component for displaying suggestion reason in the hover menu
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSuggestionReasonComponent = (attrs: Record<string, any>): MenuComponent => {
  const reasonDiv = document.createElement('div');
  reasonDiv.className = 'suggestion-reason';
  const reason = attrs?.data?.reason;
  if (reason) {
    const reasonLabel = document.createElement('strong');
    reasonLabel.textContent = `${attrs.username}: `;

    const reasonText = document.createElement('span');
    reasonText.textContent = reason;
    reasonText.className = 'reason-content';

    reasonDiv.appendChild(reasonLabel);
    reasonDiv.appendChild(reasonText);
  }

  return { dom: reasonDiv };
};
