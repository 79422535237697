import { DynamicStructuredTool } from '@langchain/core/tools';
import { z } from 'zod';
import type { ToolContext } from '../index';

export const linkDocumentsTool = ({
  getBrain,
  getShown,
  verboseToolCalls,
}: ToolContext): DynamicStructuredTool<typeof schema> => {
  const schema = z.object({
    linkFromId: z.string().describe('The ID (UUID) of the document to link from'),
    linkToId: z.string().describe('The ID (UUID) of the document to link to'),
  });

  return new DynamicStructuredTool<typeof schema>({
    name: 'link_documents',
    description:
      'Link two documents together.  This will create a link from the first document to the second document.',
    schema,
    func: async ({ linkFromId, linkToId }) => {
      // first check if these notes are in the surfaceNoteMap (faster)
      let linkFrom = getShown().surfaceNoteMap[linkFromId];
      let linkTo = getShown().surfaceNoteMap[linkToId];
      if (!linkFrom || !linkTo) {
        const noteMap = await getBrain().getNoteMapByIds([linkFromId, linkToId]);
        linkFrom = noteMap[linkFromId];
        linkTo = noteMap[linkToId];
      }
      if (verboseToolCalls) {
        console.log(
          'link_documents tool called with:',
          { linkFromId, linkToId },
          'linking documents:',
          linkFrom,
          linkTo,
        );
      }

      if (!linkFrom || !linkTo) {
        return JSON.stringify({
          status: 'error',
          message: `Unable to link documents: ${!linkFrom ? linkFromId : linkToId} not found`,
          data: null,
        });
      }
      const [updatedLinkFrom, updatedLinkTo] = getBrain().linkNotes(linkFrom, linkTo);
      if (verboseToolCalls) {
        console.log('link_documents tool linked:', { updatedLinkFrom, updatedLinkTo });
      }
      getShown().show([updatedLinkFrom.id, updatedLinkTo.id]);

      return JSON.stringify({
        status: 'success',
        message: `Linked document ${linkFromId} to document ${linkToId}`,
      });
    },
  });
};
