import React, { createContext, useContext, ReactNode, useCallback, useEffect, useRef } from 'react';
import { UUID, Note } from 'core/types';
import { applySuggestion, TextSuggestion } from 'prosemirror-suggestion-mode';
import { createEditor, EditorView } from 'core/components/proseEditor';
import { initialize as initializeProseMirror } from 'core/components/proseEditor/db';
import { useDataContext } from 'src/context/DataContext';
import { getMarkdownFromView } from 'core/components/proseEditor/utils';
import { useBrainContext } from 'src/context/BrainContext';
// Define the structure for a suggestion request
export interface AddSuggestionsOptions {
  noteId: UUID;
  suggestions: TextSuggestion[];
  username?: string;
}

// Define the context type
export interface EditorContextType {
  addSuggestions: (options: AddSuggestionsOptions) => {
    successful: number;
    failed: TextSuggestion[];
  };
  getOrCreateEditor: (element: HTMLElement, note: Note, readOnly: boolean, enableSuggestions: boolean) => EditorView;
  removeEditor: (noteId: UUID) => void;
  saveEditorContent: (noteId: UUID) => Promise<void>;
}

// Create the context
const EditorContext = createContext<EditorContextType | undefined>(undefined);

// Create the provider component
export const EditorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { annoteDB } = useDataContext();
  const { updateNotes } = useBrainContext();

  // Use ref to hold editor instances so they persist across renders
  const editorsRef = useRef<Map<UUID, { view: EditorView; note: Note }>>(new Map());

  // set annoteDB to my getDB function to work with the proseMirror core lib
  useEffect(() => {
    if (annoteDB) {
      initializeProseMirror(annoteDB);
    }
  }, [annoteDB]);

  const saveEditorContent = useCallback(
    async (noteId: UUID): Promise<void> => {
      const editorData = editorsRef.current.get(noteId);
      if (!editorData) return;

      const { view, note } = editorData;
      const newMarkdown = getMarkdownFromView(view);
      if (newMarkdown === note.value) return;

      // Update the note with new content
      await updateNotes([{ ...note, value: newMarkdown }]);

      // Update our stored reference
      editorsRef.current.set(noteId, {
        view,
        note: { ...note, value: newMarkdown },
      });
    },
    [updateNotes],
  );
  // Add the suggestions to the editor displaying them using prosemirror-suggestion-mode
  const addSuggestions = useCallback(
    ({
      noteId,
      suggestions,
      username,
    }: AddSuggestionsOptions): {
      successful: number;
      failed: TextSuggestion[];
    } => {
      const editorData = editorsRef.current.get(noteId);
      if (!editorData?.view) {
        console.error('No view found for noteId', noteId);
        return { successful: 0, failed: suggestions };
      }
      const { view } = editorData;

      const results = suggestions.map((suggestion) => ({
        suggestion,
        success: applySuggestion(view, suggestion, username || 'AnnI'),
      }));

      // saveEditorContent(noteId);
      return {
        successful: results.filter((r) => r.success).length,
        failed: results.filter((r) => !r.success).map((r) => r.suggestion),
      };
    },
    [],
  );

  const getOrCreateEditor = useCallback(
    (element: HTMLElement, note: Note, isReadOnly: boolean, enableSuggestions: boolean): EditorView => {
      // For readonly editors, always create a new instance without tracking it
      if (isReadOnly) {
        console.log('Creating standalone readonly editor for', note.id);
        // Create with readonly flag and minimal features (no suggestions)
        return createEditor(element, note, {}, true, false);
      }

      // For editable editors, check if we already have one for this note
      if (editorsRef.current.has(note.id)) {
        const editorData = editorsRef.current.get(note.id);
        // Update the note reference to ensure we're working with latest data
        if (editorData) {
          editorData.note = note;
          return editorData.view;
        }
      }

      // Create a new editable editor if one doesn't exist
      console.log('Creating new editable editor for', note.id);
      const view = createEditor(element, note, {}, false, enableSuggestions);

      // Store the editor and note reference (only for editable editors)
      editorsRef.current.set(note.id, { view, note });

      return view;
    },
    [],
  );

  const removeEditor = useCallback((noteId: UUID): void => {
    // We only track editable editors, so we only need to clean up those
    const editorData = editorsRef.current.get(noteId);
    if (editorData) {
      editorData.view.destroy();
      editorsRef.current.delete(noteId);
    }
  }, []);

  return (
    <EditorContext.Provider
      value={{
        addSuggestions,
        getOrCreateEditor,
        removeEditor,
        saveEditorContent,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

// Create a hook for easy use
export const useEditorContext = (): EditorContextType => {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error('useEditorContext must be used within an EditorProvider');
  }
  return context;
};
