// Source.tsx
import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import type { Source, UUID, Note, Annote } from 'core/types';
import { isAnnote } from 'core/types';
import { ConnectDragSource } from 'react-dnd';
import { text, colors, dimensions } from 'core/styles';
import { ShownIdsContext } from 'src/context/ShownContext';
import { getDomain } from 'core/utils/urls';
import NumberOfAnnotes from 'src/components/NumberOfAnnotes';
import { Favicon } from 'src/components/Favicon';
import { CardSize } from 'src/types';
import { useDndRef } from 'src/helpers/useDndRef';
import { AnnoteCard } from '../annote/Annote';

const Title = styled.span`
  font-family: ${text.family.reading};
  font-weight: ${text.weight.regular};
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  color: ${colors.text.header};
  flex: 1;
  padding: 0;
  margin: 0;

  &:empty::before {
    content: attr(placeholder);
  }
`;

const FullContent = styled.div`
  font-size: 1.75rem;
  line-height: 150%;
  padding: 0 ${dimensions.oneInchRem}rem ${dimensions.oneInchRem * 1.25}rem;
  font-family: ${text.family.reading};
  color: ${colors.text.primary};
  user-select: text;
  cursor: text;
  pointer-events: all !important;
  overflow-wrap: break-word; /* Allow breaking of long words */
  word-wrap: break-word; /* Legacy name for overflow-wrap */
  word-break: break-word; /* For better browser support */
  hyphens: auto; /* Enable hyphenation when needed */

  picture,
  svg,
  img {
    max-width: 100%; // Adjust this value as needed
    height: auto; // Maintain aspect ratio
  }

  h2 {
    font-size: 24px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 20px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 18px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  video {
    max-width: 100%;
    height: auto;
  }

  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* For Mozilla, since 1999 */
    white-space: -pre-wrap; /* For Opera 4-6 */
    white-space: -o-pre-wrap; /* For Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    overflow-x: auto; /* Add horizontal scrollbar if needed */
  }

  p {
    margin-bottom: 24px;
  }

  a {
    color: ${colors.text.link};
  }
`;

const Wrapper = styled.div<{ size: CardSize }>`
  display: block;
  width: ${({ size }) => (size === 'LARGE' ? `${dimensions.oneInchRem * 8.5}rem` : '37.5rem')};
  max-height: ${dimensions.oneInchRem * 11}rem;
  position: relative;
  box-shadow: 0px 1px 2px rgba(15, 15, 15, 0.1);
  border-radius: 2px;
  overflow-y: auto;

  contain: paint;

  // TODO - not sure what this is
  &:hover {
    overflow-y: auto;
  }
`;

const SourceURL = styled.a`
  color: ${colors.text.subtitle};
  font-family: ${text.family.spartan};
  font-optical-sizing: auto;
  font-size: ${text.size.small};
  font-style: normal;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SourceInfoWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  padding: 2.25rem 2rem;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.25rem;
  flex-shrink: 0;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
`;

const SourceURLWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const AnnotesList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 2.25rem;
`;

const AnnoteListItem = styled.li`
  position: relative;
`;

const AnnotesDivider = styled.div`
  width: 100%;
  opacity: 0.5;
  background: linear-gradient(180deg, #adadad 0%, rgba(217, 217, 217, 0.25) 47.92%, rgba(217, 217, 217, 0) 100%);
  height: 0.75rem;
`;

// Simple div to mark the draggable area
const DraggableArea = styled.div`
  cursor: pointer;
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${colors.bg.primary};
  padding: 2.25rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const FullDataLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${dimensions.oneInchRem / 4}rem ${dimensions.oneInchRem}rem;
`;

const FullHeaderSpacer = styled.div`
  height: ${dimensions.oneInchRem / 4}rem;
`;

const FullInfo = styled.div`
  /* position: sticky;
  top: 0;
  z-index: 1; */

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${dimensions.oneInchRem / 4}rem;
`;

const FullAuthor = styled.span`
  color: ${colors.text.primary};
  text-align: center;
  font-family: ${text.family.spartan};
  font-size: 1.75rem;
  font-style: normal;
  font-weight: ${text.weight.thin};
  line-height: normal;
`;

const FullTitle = styled.span`
  color: ${colors.text.header};
  text-align: center;
  font-family: ${text.family.reading};
  font-size: ${text.size.sourceTitle};
  font-style: italic;
  font-weight: ${text.weight.regular};
  line-height: 120%;
`;

interface SourceProps {
  source: Source;
  dragRef?: ConnectDragSource; // Make dragRef optional
  inPreview?: boolean;
  readOnly?: boolean;
}

export const SourceCard: React.FC<SourceProps> = ({ source, dragRef, inPreview = false, readOnly = false }) => {
  const { shownIds, showAafterB, hide, surfaceNoteMap } = useContext(ShownIdsContext);
  const { shownSizes, updateSize } = useContext(ShownIdsContext);
  const [shownAnnotes, setShownAnnotes] = useState<Note[]>([]);

  // TODO - right now we're assuming on all links from a source are annotes
  // this may be an issue eventually

  const size = readOnly ? 'SMALL' : shownSizes[source.id] || 'SMALL';

  const cleanURL = getDomain(source.url);

  useEffect(() => {
    const newShownAnnotes = source.links
      .filter((linkId: UUID) => shownIds.includes(linkId))
      .map((linkId: UUID) => surfaceNoteMap[linkId])
      .filter((n) => n?.type === 'annote');

    // React should handle if the array is the same
    setShownAnnotes(newShownAnnotes);
  }, [surfaceNoteMap, shownIds, source.links]);

  // If you start out with an annote, switch to ANNOTES mode
  useEffect(() => {
    if (inPreview) return;
    if (size !== 'SMALL') return;
    const hasShownAnnotes = source.links.some((linkId) => shownIds.includes(linkId));
    if (!hasShownAnnotes) return;
    updateSize(source.id, 'MEDIUM');
  }, [dragRef, inPreview]);

  useEffect(() => {
    if (size === 'LARGE') {
      // Signal the extension that there's a new source to highlight
      const event = new CustomEvent('NewSourceToRead', { detail: source.url });
      window.dispatchEvent(event);
    }
  }, [size, source.url]);

  // When display mode changes, we need to show or hide the annotes
  const changeDisplayMode = useCallback(
    (mode: CardSize): void => {
      const annoteIds = source.links.filter((linkId) => isAnnote(surfaceNoteMap[linkId]));
      if (mode === 'MEDIUM' || mode === 'LARGE') {
        showAafterB(annoteIds, source.id);
      } else {
        // minimal mode
        hide(annoteIds, true);
      }
      updateSize(source.id, mode);
    },
    [source.links, source.id, showAafterB, hide, surfaceNoteMap, shownIds, updateSize],
  );

  const sanitizedContent = (): { __html: string } => ({
    __html: DOMPurify.sanitize(source.value),
  });
  const SourceUrlJsx = (
    <SourceURLWrapper>
      <Favicon url={source.url} />
      <SourceURL href={source.url} target="_blank" rel="noopener noreferrer">
        {cleanURL}
      </SourceURL>
    </SourceURLWrapper>
  );

  const NumOfAnnotesJsx = (
    <div
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        changeDisplayMode(size === 'MEDIUM' ? 'SMALL' : 'MEDIUM');
      }}
    >
      <NumberOfAnnotes annoteCount={source.links.length} />
    </div>
  );

  const setRef = dragRef ? useDndRef(dragRef) : undefined;

  return (
    <Wrapper size={size} className="nowheel">
      {size !== 'LARGE' && (
        <>
          <DraggableArea ref={setRef}>
            <SourceInfoWrapper>
              <Title>{source.title}</Title>
              <Info>
                {SourceUrlJsx}
                {NumOfAnnotesJsx}
              </Info>
            </SourceInfoWrapper>
            {size === 'MEDIUM' && shownAnnotes.length > 0 && (
              <AnnotesList className="nodrag">
                {shownAnnotes.map((annote) => (
                  <AnnoteListItem key={annote.id}>
                    <AnnotesDivider />
                    <AnnoteCard key={annote.id} annote={annote as Annote} />
                  </AnnoteListItem>
                ))}
              </AnnotesList>
            )}
          </DraggableArea>
        </>
      )}
      {size === 'LARGE' && (
        <>
          <FullHeaderSpacer />
          <StickyHeader>
            <FullTitle>{source.title}</FullTitle>
          </StickyHeader>
          <DraggableArea ref={setRef}>
            <FullInfo>{source.details.author && <FullAuthor>{source.details.author}</FullAuthor>}</FullInfo>
            <FullDataLine>
              {SourceUrlJsx}
              {NumOfAnnotesJsx}
            </FullDataLine>
          </DraggableArea>
          <FullContent
            className="nodeselector nodrag"
            data-reader-source-url={source.url}
            data-reader-source-id={source.id}
          >
            <div dangerouslySetInnerHTML={sanitizedContent()} />
          </FullContent>
        </>
      )}
    </Wrapper>
  );
};

export default React.memo(SourceCard);
