import { useCallback } from 'react';
import type { ConnectDropTarget, ConnectDragSource } from 'react-dnd';

// This type union covers both drag and drop refs
type DndRef = ConnectDropTarget | ConnectDragSource;

export const useDndRef = (ref: DndRef): React.RefCallback<HTMLDivElement> => {
  return useCallback(
    (element: HTMLDivElement | null) => {
      if (element) {
        ref(element);
      }
    },
    [ref],
  );
};
