import { Schema } from 'prosemirror-model';
import { addSuggestionMarks } from 'prosemirror-suggestion-mode';

// const INT_LINK_PREFIX = 'https://annote.com/ids=';

const schema = new Schema({
  nodes: {
    doc: { content: 'block+' },
    paragraph: {
      content: 'inline*',
      group: 'block',
      parseDOM: [{ tag: 'p' }],
      toDOM: () => ['p', 0],
    },
    text: { group: 'inline' },
    list_item: {
      content: 'paragraph block*',
      defining: true,
      parseDOM: [{ tag: 'li' }],
      toDOM: () => ['li', 0],
    },
    bullet_list: {
      content: 'list_item+',
      group: 'block',
      parseDOM: [{ tag: 'ul' }],
      toDOM: () => ['ul', 0],
    },
    ordered_list: {
      attrs: { order: { default: 1 } },
      content: 'list_item+',
      group: 'block',
      parseDOM: [
        {
          tag: 'ol',
          getAttrs(dom) {
            return { order: dom.hasAttribute('start') ? +(dom.getAttribute('start') ?? 1) : 1 };
          },
        },
      ],
      toDOM: (node) => ['ol', { start: node.attrs.order }, 0],
    },
  },
  marks: addSuggestionMarks({
    strong: {
      parseDOM: [{ tag: 'strong' }],
      toDOM: () => ['strong', 0],
    },
    em: {
      parseDOM: [{ tag: 'i' }],
      toDOM: () => ['i', 0],
    },
    link: {
      // TODO - do we want this?  Not sure.
      attrs: {
        href: {},
        internal: { default: false }, // 'internal' marks whether it's an internal vs external link
      },
      toDOM(node) {
        return [
          'a',
          {
            href: node.attrs.href,
            class: node.attrs.internal ? 'internal-link' : '',
          },
          0,
        ];
      },
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs(dom) {
            return {
              href: dom.getAttribute('href') || '',
              title: dom.getAttribute('title') || '',
              internal: dom.classList.contains('internal-link'),
            };
          },
        },
      ],
    },
  }),
});

export { schema };
