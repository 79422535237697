import { z } from 'zod';
import { DynamicStructuredTool } from '@langchain/core/tools';
import type { ToolContext } from '../index';

// TODO - should get supabaeClient from annoteDB?
export const getRelevantDocumentSectionsTool = ({ getAnnoteDB }: ToolContext): DynamicStructuredTool<typeof schema> => {
  const schema = z.object({
    query: z.string().describe('The search query to match document sections for RAG.'),
    threshold: z.number().optional().default(0.5).describe('The threshold for matching.'),
    limit: z.number().optional().default(10).describe('Maximum number of document sections to return.'),
  });

  return new DynamicStructuredTool({
    name: 'get_relevant_document_sections',
    description: 'Fetch relevant document sections from the RAG database based on a search query.',
    schema,
    func: async ({ query, threshold, limit }): Promise<string> => {
      //   const baseUrl: string = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;
      //   const endpoint: string = `${baseUrl}/getRelevantDocumentSections`;
      //   const {
      //     data: { session },
      //     error,
      //   } = await supabaseClient.auth.getSession();
      //   if (error) {
      //     throw new Error('Error getting session for getRelevantDocumentSections', error);
      //   }
      //   // todo - can use annoteDB.fetch here to have the authed user
      //   const userAuthToken = session?.access_token;
      //   console.log('User Auth Token:', userAuthToken);
      //   const response: Response = await fetch(endpoint, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${userAuthToken}`,
      //     },
      //     body: JSON.stringify({ query, threshold, limit }),
      //   });

      //   if (!response.ok) {
      //     const errorJson = await response.json();
      //     throw new Error(`Failed to fetch relevant document sections: ${errorJson?.error || response.statusText}`);
      //   }

      //   const { data } = await response.json();
      //   return JSON.stringify(data);

      const annoteDB = getAnnoteDB();
      try {
        const result = await annoteDB?.getRelevantSections(query, threshold, limit);
        console.log('relevant documents result is', result);
        return JSON.stringify(result);
      } catch (error) {
        console.error('Error getting relevant document sections', error);
        return JSON.stringify({ error: 'Error getting relevant document sections' });
      }
    },
  });
};
