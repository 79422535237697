import React, { useContext } from 'react';
import styled from 'styled-components';
import { Annote } from 'core/types';
import { ProseEditor } from 'src/components/ProseEditor';
import { SearchContext, highlightSearchTerm } from 'src/context/SearchContext';
import { Highlight, Wrapper, NoteWrapper } from './Annote';

const MAX_SMALL_CARD_CHARS = 53;

const ListItemWrapper = styled(Wrapper)`
  width: 32rem;
  padding: 12px 12px 6px 12px;

  .ProseMirror {
    cursor: pointer;
    pointer-events: none;
  }
  * {
    pointer-events: none;
  }
`;

const getShortText = (textToShorten: string, maxLength: number, searchTerm: string): string => {
  if (textToShorten.length <= maxLength) return textToShorten;

  if (!searchTerm) return `${textToShorten.substring(0, maxLength)}…`;

  // Get the short text around the 1st occurrence of the search term
  const searchTermIndex = textToShorten.indexOf(searchTerm);
  const start = Math.max(0, searchTermIndex - maxLength / 2 - 1);
  const end = Math.min(textToShorten.length, searchTermIndex + maxLength / 2);
  const prefix = start > 0 ? '…' : '';
  const suffix = end < textToShorten.length ? '…' : '';
  return `${prefix}${textToShorten.substring(start, end).trim()}${suffix}`;
};

// We can't use html in the ProseEditor, so to highlight the search term here we just bold itw
const boldSearchTerm = (text: string, searchTerm: string): string => {
  return text.replace(searchTerm, `**${searchTerm}**`);
};

// The small list-version/view of an Annote
export const ListAnnoteCard: React.FC<{ annote: Annote }> = ({ annote }) => {
  const { searchTerm } = useContext(SearchContext);

  if (!annote) return null;

  const shortNote = getShortText(annote.value, MAX_SMALL_CARD_CHARS, searchTerm);
  // Adjust quote length based on the length of the shortNote
  const quoteLen = MAX_SMALL_CARD_CHARS - shortNote.length;
  const shortQuote = getShortText(annote.title, quoteLen, searchTerm);

  // Need to wrap the shortNote in an Annote to pass to the ProseEditor
  const shortAnnote = {
    ...annote,
    value: boldSearchTerm(shortNote, searchTerm),
  };
  return (
    <ListItemWrapper>
      <Highlight>{highlightSearchTerm(shortQuote, searchTerm)}</Highlight>
      <NoteWrapper>
        <ProseEditor note={shortAnnote} isReadOnly={true} />
      </NoteWrapper>
    </ListItemWrapper>
  );
};
