import { z } from 'zod';
import { DynamicStructuredTool } from '@langchain/core/tools';
import type { UUID, Note } from 'core/types';
import { createLLM } from 'src/db/AnnI/llm';
import type { ToolContext } from '../index';
import { TextEditSuggestion } from './suggestEdits';

export const generateNoteContentTool = ({
  getEditorContext,
  getBrain,
  getShown,
  verboseToolCalls,
}: ToolContext): DynamicStructuredTool<typeof schema> => {
  const schema = z.object({
    noteId: z.string().describe('The ID (UUID) of the note to generate content for'),
    customInstructions: z.string().optional().describe('Optional instructions for content generation'),
  });
  // eslint-disable-next-line no-param-reassign
  verboseToolCalls = true;
  return new DynamicStructuredTool({
    name: 'generate_note_content',
    description:
      // eslint-disable-next-line max-len
      'Generate content for an existing note that has a title but empty or minimal content. This will automatically gather context from linked notes and parent notes, then create appropriate content.',
    schema,
    func: async ({ noteId, customInstructions }) => {
      const editorContext = getEditorContext();

      if (!editorContext) {
        return JSON.stringify({
          status: 'error',
          message: 'Editor context is not available',
        });
      }

      // Get the current note to make sure it exists
      let note = getShown().surfaceNoteMap[noteId as UUID];
      if (!note) {
        const noteMap = await getBrain().getNoteMapByIds([noteId as UUID]);
        note = noteMap[noteId as UUID];
      }

      if (!note) {
        return JSON.stringify({
          status: 'error',
          message: `Unable to generate content: Note ${noteId} not found`,
        });
      }

      if (note.type !== 'takeaway') {
        return JSON.stringify({
          status: 'error',
          message: `Unable to generate content: Document ${noteId} is not of type note`,
        });
      }

      // Gather context from linked notes and parent note
      const relatedNotesMap: Record<UUID, Note> = {};
      const contextData: { title: string; content: string; relationship: string }[] = [];

      const contextNoteIds: UUID[] = note.links || [];
      if (note.createdFromId) {
        contextNoteIds.push(note.createdFromId as UUID);
      }

      // if it has no context, use any other shown notes as context
      if (contextNoteIds.length < 0) {
        contextNoteIds.push(...getShown().shownIds.filter((id) => id !== noteId));
      }
      let contextNoteMap = await getBrain().getNoteMapByIds(contextNoteIds);

      // for any annotes, ensure their source is also included
      const extraSourceIds: UUID[] = [];
      Object.values(contextNoteMap).forEach((contextNote) => {
        if (contextNote.type === 'annote') {
          const sourceId = contextNote.createdFromId;
          if (sourceId && !contextNoteIds.includes(sourceId)) {
            extraSourceIds.push(sourceId);
          }
        }
      });
      if (extraSourceIds.length > 0) {
        contextNoteIds.push(...extraSourceIds);
        const extraSourceNoteMap = await getBrain().getNoteMapByIds(extraSourceIds);
        contextNoteMap = { ...contextNoteMap, ...extraSourceNoteMap };
      }

      const createdFromNote = note.createdFromId ? contextNoteMap[note.createdFromId] : undefined;

      const parentNote = createdFromNote?.createdFromId ? contextNoteMap[createdFromNote.createdFromId] : undefined;
      // remove the parentNote from the contextNoteMap
      if (parentNote) {
        delete contextNoteMap[parentNote.id];
      }

      // Build the prompt for generating the note content
      let prompt = `
      You are a helpful assistant that generates content for a note.
      You will be given the title of a note and some context.
      You will need to generate a new piece of content for the note based only on the context provided.
      DO NOT make up or add any information outside of what is provided in the context.
      DO NOT repeat the title, return only the content.
      Write in a clear, concise style.
      Keep the content below 60 words.
      `;

      if (customInstructions) {
        prompt += `
Special instructions for this generation task:
${customInstructions}
        `;
      }

      if (parentNote) {
        prompt += `
The note was created and linked from the following note: ${parentNote.title}
${parentNote.value}
        `;
      }

      if (contextData.length > 0) {
        prompt += `
        Here is some context for the note:
        ${contextData.map((context) => `${context.title}\n${context.content}`).join('\n\n')}
        `;
      }

      prompt += `
Here is the note title: ${note.title}
Please generate comprehensive content for this note based on the provided context.
`;

      if (verboseToolCalls) {
        console.log('generate_note_content tool prompt:', prompt);
      }

      try {
        // Now make the call to the LLM
        const llm = await createLLM(getBrain().customFetch);
        const generatedContent = await llm.invoke(prompt);

        if (verboseToolCalls) console.log('generate_note_content tool generated content:', generatedContent.content);
        const { addSuggestions } = editorContext;

        // Create a suggestion that replaces everything (or nothing if empty) with the new content
        const suggestion: TextEditSuggestion = {
          textToReplace: note.value || '',
          textReplacement: generatedContent.content.toString(),
          reason: 'Generated content based on note context',
        };

        // Add the suggestion request to the context
        if (verboseToolCalls) {
          console.log('generate_note_content tool is adding content:', noteId, generatedContent, 'username: AnnI');
        }

        const { failed } = addSuggestions({
          noteId: noteId as UUID,
          suggestions: [suggestion],
          username: 'AnnI',
        });

        if (failed.length > 0) {
          return JSON.stringify({
            status: 'failed',
            message: `Failed to generate content for note ${noteId} based on ${
              Object.keys(relatedNotesMap).length
            } related notes`,
            relatedNoteIds: Object.keys(relatedNotesMap),
            failedSuggestion: suggestion,
          });
        }
        return JSON.stringify({
          status: 'success',
          message: `Generated content for note ${noteId} based on ${Object.keys(relatedNotesMap).length} related notes`,
          relatedNoteIds: Object.keys(relatedNotesMap),
        });
      } catch (err) {
        console.error('Error generating note content:', err);
        return JSON.stringify({
          status: 'error',
          message: 'Failed to generate note content',
          error: String(err),
        });
      }
    },
  });
};
