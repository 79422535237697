import styled from 'styled-components';
import { Note } from 'core/types';
import { ReactComponent as AIIcon } from 'src/components/Icon/icons/ai.svg';
import { ReactComponent as DotsIcon } from 'src/components/Icon/icons/dots.svg';
import { useState } from 'react';

import CardExtrasMenu from './CardExtrasMenu';
import AIMenu from './AIMenu';
import { CardMenuButton } from './CardButton';
import { SizeToggleButton } from './SizeToggleButton';
import { SuggestionButtons } from './SuggestionButtons';

const MenuButtonWrapper = styled.div<{ hasOpenMenu: boolean; isSuggestion: boolean }>`
  position: absolute;
  top: 8px;
  right: 8px;
  transition: opacity 0.2s ease;
  z-index: 2;
  background-color: inherit;
  border-radius: 8px;
  padding: 4px;

  ${({ hasOpenMenu, isSuggestion }) => {
    const opacity = hasOpenMenu || isSuggestion ? 1 : 0;
    return `
    opacity: ${opacity};
  `;
  }}

  .card:hover & {
    opacity: 1;
  }
`;

type OpenMenu = 'ai' | 'extras' | null;

export const CardButtonsBar: React.FC<{ note: Note }> = ({ note }) => {
  const [openMenu, setOpenMenu] = useState<OpenMenu>(null);

  return (
    <MenuButtonWrapper hasOpenMenu={openMenu !== null} isSuggestion={note.isSuggestion ?? false}>
      {note.isSuggestion ? (
        <SuggestionButtons note={note} />
      ) : (
        <>
          <SizeToggleButton note={note} />

          <CardMenuButton
            iconSVG={AIIcon}
            isOpen={openMenu === 'ai'}
            onToggle={() => setOpenMenu(openMenu === 'ai' ? null : 'ai')}
          >
            <AIMenu note={note} />
          </CardMenuButton>

          <CardMenuButton
            iconSVG={DotsIcon}
            isOpen={openMenu === 'extras'}
            onToggle={() => setOpenMenu(openMenu === 'extras' ? null : 'extras')}
          >
            <CardExtrasMenu note={note} />
          </CardMenuButton>
        </>
      )}
    </MenuButtonWrapper>
  );
};
