import { AnySelector } from './selectorTypes';

export type TypeOfNote = 'takeaway' | 'source' | 'annote';
export const TYPES_OF_NOTES: TypeOfNote[] = ['takeaway', 'source', 'annote'];
export const NOTE_TAB_LABELS = { all: 'All', takeaway: 'Notes', source: 'Articles', annote: 'Highlights' };
export type UUID = string;

export type Generator = 'human' | 'ai';

export type SharedDetails = {
  generator?: Generator;
  tool?: string;
};

export type SourceDetails = SharedDetails & {
  textContent: string;
  length: number;
  excerpt: string;
  byline: string;
  dir: string;
  siteName: string;
  lang: string;
  author: string | null;
  publishedTime: string | null;
  canonicalUrl?: string;
};

export type AnnoteDetails = SharedDetails & {
  selectors: AnySelector[];
};

export type TakeawayDetails = SharedDetails & {
  // sourceId: UUID;
};

export type NoteDetails = AnnoteDetails | SourceDetails | TakeawayDetails;

export type Position = { x: number; y: number };

// a briefer version of a note to lower token usage on AI tools
export type NoteSummary = {
  id: UUID;
  title: string;
  type: TypeOfNote;
};

// a briefer version of a note from search results
export type SearchNote = {
  id: UUID;
  title: string;
  value: string;
  type: TypeOfNote;
  url?: string;
};

// ! When this is updated make sure to update ensureOnlyNoteFields in utils/notes.ts
export type Note = {
  id: UUID;
  userId?: UUID; // userId should be auto set by the server
  type: TypeOfNote; // one of 'annote', 'source', 'takeaway'
  title: string;
  value: string;
  url?: string; // url if a source
  createdFromId?: UUID; // id of the note that this note was created from if it was created from another note
  details?: NoteDetails; // json object with the details of the note specific to the type of note
  createdAt: string;
  syncedAt?: string | null;
  deletedAt?: string | null; // null if not yet deleted
  links: UUID[]; // denormalized list of ids of notes that are linked to this note
  backlinks: UUID[]; // denormalized list of ids of notes that have this note as a backlink
  isSuggestion?: boolean; // true if this note is a suggestion from the AI
  // These take a lot of space and are not used in the client
  // embedding: number[]; // vector embedding of the note
  // fts: string; // full text search vector of the note
};

export type SearchResults = Note[] | SearchNote[];

export type Profile = {
  userId: UUID;
  displayName: string;
  email: string;
  photoUrl?: string;
  createdAt: string;
  syncedAt: string;
  deletedAt?: string | null;
  blockedHosts: string[];
  isStaff: boolean;
};

export type NoteMap = Record<UUID, Note>;

export type Annote = Note & {
  type: 'annote';
  createdFromId: string;
  details: AnnoteDetails;
};

export type Source = Note & {
  type: 'source';
  url: string;
  details: SourceDetails;
};

export type Takeaway = Note & {
  type: 'takeaway';
};

// the result of match_document_sections we want from querying the document_sections table
export type DocumentSection = {
  documentId: UUID;
  index: number;
  content: string;
  similarity: number;
};

export type RelatedResult = {
  id: UUID;
  title: string;
  similarity: number;
};

export type Database = {
  Profile: Profile;
  Notes: Note;
};

export type LinkKind = 'tree' | 'graph';

export type LinkInfo = {
  fromNote: Note;
  toNote: Note;
  kind: LinkKind;
};

export const isAnnote = (note: Note): note is Annote => {
  return note?.type === 'annote';
};

export const isSource = (note: Note): note is Source => {
  return note?.type === 'source';
};

export const isSearchNote = (note: Note | SearchNote): note is SearchNote => {
  // check for the createdAt field.  search notes don't have it
  return !('createdAt' in note);
};
