export const text = {
  family: {
    primary: 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji"',
    handwriting: "'Gloria Hallelujah', cursive",
    reading: "'Baskervville', serif",
    spartan: "'League Spartan', sans-serif",
  },

  size: {
    primary: '14px',
    small: '12px',
    handwriting: '80%',
    reading: '16px',
    sourceTitle: '3rem',
    sourceTitleSticky: '22px',
  },

  height: {
    primary: '16px',
    highlight: '24px',
  },

  weight: {
    bold: '600',
    medium: '500',
    regular: '400',
    thin: '300',
  },
};
