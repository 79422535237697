import { IDatabase } from '../../dbs/types';

let annoteDB: IDatabase;

// Setup the database and add the suggest menu to the document
export const initialize = (database: IDatabase): void => {
  annoteDB = database;
};

// call so the editors can access the database directly
// used for suggestLinkMenu and linkSelectPlugin
export const getDB = (): IDatabase => annoteDB;
