import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { EditorView } from 'core/components/proseEditor';
import { Note } from 'core/types';
import styled from 'styled-components';
import { colors, text } from 'core/styles';
import { FocusContext } from 'src/context/FocusContext';
import { suggestLinkMenu } from 'core/components/proseEditor/suggestLinkMenu';
import { useEditorContext } from 'src/context/EditorContext';

const EditorContainer = styled.span<{
  isInline: boolean;
  placeholder: string;
  focusedPlaceholder: string;
}>`
  ${({ isInline }) =>
    !isInline &&
    `
      display: flex;
      flex-direction: column;
      height: 100%; // Take full height of parent
      flex: 1; // Add this to make it grow within flex parents
      width: 100%; // Ensure full width
      min-height: 100%; // Ensure it fills container
      position: relative;
    `}

  .ProseMirror {
    outline: none;
    white-space: pre-wrap; /* Preserve spaces and line breaks */
    word-wrap: break-word; /* Allow long words to be broken */
    ${({ isInline }) =>
      !isInline &&
      `
          flex: 1; // Fill remaining space
          min-height: 100%; // Ensure it fills container
        `}
  }

  .ProseMirror.read-only {
    cursor: default;
    pointer-events: none;
  }

  // This is the equivalent of .ProseMirror:empty::after but prosemirror is never really empty
  .ProseMirror p:first-child:last-child:has(br:only-child):before {
    content: ${({ placeholder }) => `"${placeholder}"`};
    font-family: ${text.family.primary};
    font-size: ${text.size.primary};
    font-weight: ${text.weight.regular};
    color: ${colors.text.placeholder};
    pointer-events: none;
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
  }

  .ProseMirror.ProseMirror-focused p:first-child:last-child:has(br:only-child):before {
    content: ${({ focusedPlaceholder }) => `"${focusedPlaceholder}"`};
  }

  // Suggestion Styles
  .suggestion-add {
    color: green;
    text-decoration: underline;
    border-radius: 2px;
  }
  .suggestion-delete {
    text-decoration: line-through;
    color: red;
  }
  .suggestion-hover-menu {
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    display: none; /* Hide by default */
    position: absolute;
    z-index: 10;
    bottom: 100%; /* Position above the text */
    left: 0;
    margin-bottom: 0px; /* Add space between hover-menu and text */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    min-width: 150px;
    white-space: nowrap;
    transition-delay: 0.1s;
    transition-property: display;
    color: black;
  }

  /* Create a relative positioning context for the hover-menu */
  .suggestion-add,
  .suggestion-delete {
    position: relative;
  }

  /* Add a pseudo-element to bridge the gap between suggestion and hover-menu */
  .suggestion-add::before,
  .suggestion-delete::before {
    content: '';
    position: absolute;
    height: 10px; /* Height of the invisible bridge */
    width: 100%;
    bottom: 100%;
    left: 0;
    z-index: 9; /* Below the hover-menu but still part of the hover path */
  }

  /* Show hover-menu when hovering over suggestion marks */
  .suggestion-delete:hover .suggestion-hover-menu,
  .suggestion-add:hover .suggestion-hover-menu,
  .suggestion-hover-menu:hover {
    display: block;
  }

  /* Style for suggestion hover menu and but */
  .suggestion-buttons {
    display: flex;
    gap: 5px;
    margin-top: 5px;
  }

  .suggestion-accept,
  .suggestion-reject {
    padding: 2px 8px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
  }

  .suggestion-accept {
    background-color: #4caf50;
    color: white;
  }

  .suggestion-reject {
    background-color: #f44336;
    color: white;
  }

  .suggestion-accept:hover {
    background-color: #45a049;
  }

  .suggestion-reject:hover {
    background-color: #d32f2f;
  }

  .suggestion-info {
    margin-bottom: 5px;
  }
`;

export const ProseEditor: React.FC<{
  note: Note;
  placeholder?: string;
  focusedPlaceholder?: string;
  isReadOnly?: boolean;
  isInline?: boolean;
}> = ({ note, placeholder = 'placeholder', focusedPlaceholder = 'focused', isReadOnly = false, isInline = true }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const editorViewRef = useRef<EditorView | null>(null);
  const { focusedNoteId, focusedTarget } = useContext(FocusContext);
  const { getOrCreateEditor, removeEditor, saveEditorContent } = useEditorContext();

  // Use a stable reference to the note id
  const noteId = note.id;

  const handleBlur = useCallback(async (): Promise<void> => {
    if (!editorViewRef.current) return;
    if (suggestLinkMenu.recentlyClicked) return; // not a real blur if they clicked on a suggestion

    // Only save content for editable editors
    if (!isReadOnly) {
      await saveEditorContent(noteId);
    }
  }, [noteId, saveEditorContent, isReadOnly]);

  useEffect((): (() => void) | undefined => {
    // Only proceed when we have a DOM element to attach to
    if (!editorRef.current) return undefined;

    // Use our centralized editor management
    const enableSuggestions = !isReadOnly; // No need for suggestions in readonly mode
    const view = getOrCreateEditor(editorRef.current, note, isReadOnly, enableSuggestions);

    // Store reference locally for direct access
    editorViewRef.current = view;

    // Cleanup on component unmount
    return () => {
      if (isReadOnly) {
        // For readonly views, we need to manually destroy the editor
        // since it's not tracked in the context
        if (editorViewRef.current) {
          editorViewRef.current.destroy();
        }
      } else {
        // For editable views, use the context's removeEditor
        removeEditor(noteId);
      }
      editorViewRef.current = null;
    };
  }, [getOrCreateEditor, removeEditor, noteId, isReadOnly, note]);

  // Handle Focus changes
  useEffect(() => {
    if (isReadOnly) return; // Skip for readonly editors
    if (!editorViewRef.current || !editorRef.current) return;
    if (focusedNoteId !== noteId) return;

    // By default, focus the editor unless they've explicitly clicked on a different target
    if (focusedTarget && !(editorRef.current as HTMLElement).contains(focusedTarget)) return;

    editorViewRef.current.focus();
  }, [focusedNoteId, noteId, focusedTarget, isReadOnly]);

  return (
    <EditorContainer
      ref={editorRef}
      onBlur={handleBlur}
      isInline={isInline}
      placeholder={placeholder}
      focusedPlaceholder={focusedPlaceholder}
    />
  );
};
