import { history, undo, redo } from 'prosemirror-history';
import { keymap } from 'prosemirror-keymap';
import { splitListItem } from 'prosemirror-schema-list';

import {
  baseKeymap,
  chainCommands,
  newlineInCode,
  createParagraphNear,
  liftEmptyBlock,
  splitBlock,
} from 'prosemirror-commands';
// TODO - the gap Cursor has a gapcursor.css that's required with it
import { gapCursor } from 'prosemirror-gapcursor'; // better cursor behavior
// import { dropCursor } from 'prosemirror-dropcursor'; // better cursor for drag and dropping
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { inputRules } from 'prosemirror-inputrules';
import { suggestionModePlugin } from 'prosemirror-suggestion-mode';
import { createSuggestionReasonComponent } from './hoverMenu';
import { schema } from './schema';
import { autocompletPlugin } from './suggestPlugin';
import { parser } from './markdown';
import { suggestLinkMenu } from './suggestLinkMenu';
import { rules } from './rules';
import { Note } from '../../types';
// import linkSelectedPlugin from './linkSelectedPlugin';

const handleEnterForSuggestLinkMenu = (): boolean => {
  if (suggestLinkMenu.isShown()) {
    suggestLinkMenu.chooseSelected();
    return true;
  }
  return false;
};

// extra keys
const extraKeymap = {
  'Mod-z': undo,
  'Mod-y': redo,
  'Mod-Shift-z': redo,

  ArrowDown: () => {
    if (suggestLinkMenu.isShown()) {
      suggestLinkMenu.selectNext();
      return true;
    }
    return false;
  },
  ArrowUp: () => {
    if (suggestLinkMenu.isShown()) {
      suggestLinkMenu.selectPrevious();
      return true;
    }
    return false;
  },
  Enter: chainCommands(
    handleEnterForSuggestLinkMenu,
    newlineInCode,
    splitListItem(schema.nodes.list_item),
    createParagraphNear,
    liftEmptyBlock,
    splitBlock,
  ),

  Escape: () => {
    if (suggestLinkMenu.isShown()) {
      suggestLinkMenu.hide();
      return true;
    }
    return false;
  },
};

// returns a new editor view
const createEditor = (
  container: HTMLElement,
  note: Note,
  options = {},
  readOnly = false,
  enableSuggestions = false,
): EditorView => {
  const doc = parser.parse(`${note.value}`) || undefined;

  const plugins = [
    inputRules({ rules }),
    autocompletPlugin({ note }),
    keymap({ ...baseKeymap, ...extraKeymap }),
    history(),
    gapCursor(),
  ];

  if (enableSuggestions) {
    plugins.push(
      suggestionModePlugin({
        username: 'AnnI',
        hoverMenuOptions: {
          components: {
            createInfoComponent: createSuggestionReasonComponent,
          },
        },
        inSuggestionMode: false, // don't start in suggestion mode
      }),
    );
  }
  const state = EditorState.create({ doc, plugins });

  const view = new EditorView(container, {
    state,
    dispatchTransaction(transaction) {
      if (readOnly) return;
      const newState = view.state.apply(transaction);
      view.updateState(newState);
    },
    handleKeyDown: (v, event) => {
      if (readOnly) return true;
      event.stopPropagation();
      return false;
    },
    editable: () => !readOnly,

    ...options,
  });

  // Add the "nodrag" class so react flow won't interact with it
  view.dom.classList.add('nodrag');

  if (readOnly) {
    view.dom.classList.add('read-only');
  }

  return view; // Returning the view may be useful for further manipulation
};

export { createEditor, EditorView };
