import { z } from 'zod';
import { DynamicStructuredTool } from '@langchain/core/tools';
import type { Note } from 'core/types';
import type { ToolContext } from '../index';

const schema = z.object({
  documentIds: z.array(z.string()).describe('A list of document IDs (UUIDs)'),
});

export const getDocumentsByIdsTool = ({
  getBrain,
  getShown,
  verboseToolCalls,
}: ToolContext): DynamicStructuredTool<typeof schema> => {
  return new DynamicStructuredTool({
    name: 'get_documents_by_ids',
    description: `Get the full data of a list of documents by their ids. Returns both found documents 
      and a list of any IDs that couldn't be found. This is useful to hydrate the summarized results 
      returned from search or other tools. Notes newly suggested by AI will not be listed.`,
    schema,
    func: async ({ documentIds }) => {
      const result = {
        found: [] as Note[],
        notFound: [] as string[],
      };

      // first check if these documents are in the surfaceNoteMap (faster)
      const surfaceMatches = documentIds.map((id) => {
        const document = getShown().surfaceNoteMap[id];
        if (document) {
          result.found.push(document);
          return true;
        }
        return false;
      });

      // For any IDs not found in surface map, try getting from brain
      const missingIds = documentIds.filter((_, index) => !surfaceMatches[index]);
      if (missingIds.length > 0) {
        const noteMap = await getBrain().getNoteMapByIds(missingIds);
        missingIds.forEach((id) => {
          if (noteMap[id]) {
            result.found.push(noteMap[id]);
          } else {
            result.notFound.push(id);
          }
        });
      }

      if (verboseToolCalls) {
        console.log('get_documents_by_ids results:', {
          foundCount: result.found.length,
          notFoundCount: result.notFound.length,
          notFoundIds: result.notFound,
        });
      }

      return JSON.stringify(result);
    },
  });
};
