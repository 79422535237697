import { z } from 'zod';
import { DynamicStructuredTool } from '@langchain/core/tools';
import type { ToolContext } from '../index';

export const findRelatedDocumentsTool = ({ getAnnoteDB }: ToolContext): DynamicStructuredTool<typeof schema> => {
  const schema = z.object({
    docIds: z.array(z.string()).describe('The IDs (UUIDs) of the documents to find related documents for'),
    limit: z.number().optional().default(10).describe('The maximum number of related documents to return (optional)'),
    similarityThreshold: z
      .number()
      .optional()
      .default(0.5)
      .describe('The similarity threshold for related notes (optional)'),
  });

  return new DynamicStructuredTool({
    name: 'find_related_documents',
    description: 'Find documents related to the given document IDs based on embedding similarity.',
    schema,
    func: async ({ docIds, limit, similarityThreshold }) => {
      if (!getAnnoteDB()) {
        return JSON.stringify({
          status: 'error',
          message: 'Database is not yet initialized',
        });
      }
      try {
        const data = await getAnnoteDB()?.findRelatedNotes(docIds, limit, similarityThreshold);
        return JSON.stringify({
          status: 'success',
          relatedDocuments: data,
        });
      } catch (err) {
        console.error('Unexpected error:', err);
        return JSON.stringify({
          status: 'error',
          message: 'An unexpected error occurred',
        });
      }
    },
  });
};
