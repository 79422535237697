import { ChatOpenAI } from '@langchain/openai';
import { AgentStep } from '@langchain/core/agents';

// const DEFAULT_MODEL = 'gpt-3.5-turbo-1106';
const DEFAULT_MODEL = 'gpt-4o-mini';
// const DEFAULT_MODEL = 'o3-mini';
// const DEFAULT_MODEL = 'o1-mini';

const functionsUrl = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;
if (!functionsUrl) throw new Error('AnnI - llm - No functions URL found');

interface CallbackOptions {
  onToken?: (token: string) => void;
  onStep?: (step: AgentStep) => void;
}

export const getSupabaseToken = async (): Promise<string> => {
  const { supabaseClient } = await import('src/db/supabase');
  const {
    data: { session },
    error,
  } = await supabaseClient.auth.getSession();

  if (error) {
    throw new Error('Error getting session for llm', error);
  }

  if (!session?.access_token) {
    throw new Error('No authentication token available');
  }

  return session.access_token;
};

export const createLLM = async (
  customFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  callbacks?: CallbackOptions,
  model: string = DEFAULT_MODEL,
  temperature: number = 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maxCompletionTokens: number = 4096,
): Promise<ChatOpenAI> => {
  const baseUrl = `${functionsUrl}/openai-proxy`;
  console.log('Creating LLM with baseUrl', baseUrl);

  return new ChatOpenAI({
    model,
    temperature,
    // max_completion_tokens: maxCompletionTokens,
    apiKey: 'overwritten',
    streaming: true,
    callbacks: [
      {
        handleLLMNewToken(token: string) {
          callbacks?.onToken?.(token);
        },
      },
    ],
    configuration: {
      baseURL: baseUrl,
      defaultHeaders: {
        Authorization: `Bearer OVERRIDEN`,
        'Content-Type': 'application/json',
      },
      fetch: customFetch,
    },
  });
};
