import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { isSupabaseDatabase } from 'core/dbs/supabaseDb';
import { Profile } from 'core/types';
import { useDataContext } from './DataContext';

const setUserForSentry = (user: Profile | null): void => {
  if (user?.userId) {
    Sentry.setUser({ id: user.userId, email: user.email });
  } else {
    Sentry.setUser(null);
  }
};

interface ProfileContextType {
  profile: Profile | null;
  updateProfile: (profile: Profile) => Promise<void>;
  fetchProfile: () => Promise<Profile | null>;
  logout: () => Promise<void>;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const { annoteDB } = useDataContext();

  const updateProfile = useCallback(
    async (updatedProfile: Profile): Promise<void> => {
      if (!annoteDB) throw new Error('annoteDB is not initialized');
      setProfile(updatedProfile);
      setUserForSentry(updatedProfile);
      await annoteDB.updateProfile(updatedProfile);
    },
    [annoteDB, setProfile],
  );

  // If the user has just gotten authed, this will resetup the profile state
  const fetchProfile = useCallback(async (): Promise<Profile | null> => {
    if (!annoteDB) return null;
    const prof = await annoteDB.getProfile();
    setUserForSentry(prof);
    setProfile(prof);
    if (!prof) console.log('User not authenticated');

    console.log('got user profile.  are they staff?', prof?.isStaff);
    // debug things for staff
    if (prof?.isStaff) {
      console.log('updated profile is staff:', prof);
      window.annoteDB = annoteDB;
      window.profile = prof;
      if (isSupabaseDatabase(annoteDB)) {
        window.supabase = annoteDB.db;
      }
    }

    return prof;
  }, [annoteDB]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const logout = useCallback(async (): Promise<void> => {
    console.log('inside profile logout', annoteDB, 'is supabase database?', annoteDB && isSupabaseDatabase(annoteDB));

    if (annoteDB && isSupabaseDatabase(annoteDB)) {
      await annoteDB.db.auth.signOut();
    }

    // Clear all Supabase auth tokens
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('sb-')) {
        window.localStorage.removeItem(key);
      }
    });

    // Clear any Google OAuth state from localStorage
    window.localStorage.removeItem('supabase.auth.token');
    window.localStorage.removeItem('supabase.auth.refreshToken');

    setProfile(null);
  }, [annoteDB]);
  return (
    <ProfileContext.Provider value={{ profile, updateProfile, fetchProfile, logout }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
